<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Дата начала действия:"
          :invalid-feedback="errors.dateStart"
          :state="states.dateStart"
        >
          <date
            size="sm"
            format-output="YYYY-MM-DD"
            :value="item.dateStart"
            :state="states.dateStart"
            :disabled="loading"
            @selected="updateValue('dateStart', $event)"
          ></date>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Дата окончания действия:"
          :invalid-feedback="errors.dateEnd"
          :state="states.dateEnd"
        >
          <date
            size="sm"
            format-output="YYYY-MM-DD"
            :value="item.dateEnd"
            :state="states.dateEnd"
            :disabled="loading"
            @selected="updateValue('dateEnd', $event)"
          ></date>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Date from '../../components/fields/Date.vue';

export default {
  components: {
    Date,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
  },
};
</script>
