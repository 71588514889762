import humps from 'humps';
import collectionServiceFactory from './collectionServiceFactory';
import copyPrices from '../api/prices';

const pricesService = collectionServiceFactory('prices');

export default {
  ...pricesService,

  async copyPrices(params) {
    const [err, data] = await copyPrices(humps.decamelizeKeys(params));

    return [humps.camelizeKeys(err), humps.camelizeKeys(data)];
  },
};
