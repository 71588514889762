<template>
  <cinema-page
    route-name="priceGroups"
    route-param-name="cinemaId"
  >
    <template #scroll="{ cinemaId }">
      <div>
        <collection-provider
          collection="priceGroups"
          ref="collection"
          :filter="{ cinemaId }"
          :disable-fetching="!cinemaId"
        >
          <template #default="{ data, actions }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              :panel-actions="datatable.panelActions"
              @item-action="performItemAction($event)"
              @panel-action="performPanelAction($event)"
            >
              <template #cell(dateStart)="data">
                {{ $datetime.formatDate(data.item.dateStart) }}
              </template>

              <template #cell(dateEnd)="data">
                {{ $datetime.formatDate(data.item.dateEnd) }}
              </template>

              <template #cell(enabled)="data">
                {{ data.item.isEnabled ? 'Включена' : 'Отключена' }}
              </template>

              <template #cell(prices)="data">
                <a class="text-primary cursor-pointer" @click="openPrices(data.item.id)">{{ data.item.prices.length ? formatPricesCount(data.item.prices.length) : 'Нет политик' }}</a>
              </template>
            </datatable>
          </template>
        </collection-provider>

        <modal-model-form
          v-if="modals.priceGroup.show"
          create-title="Создание группы ценовых политик"
          edit-title="Редактирование группы ценовых политик"
          collection="priceGroups"
          :id="modals.priceGroup.id"
          :init="{ cinemaId }"
          @close="modals.priceGroup.show = false"
          @saved="$refs.collection.fetchItems()"
        >
          <template #default="{ data, actions }">
            <price-group-form
              :loading="data.loading"
              :errors="data.errors"
              :states="data.states"
              :item="data.item"
              @change="actions.setValue($event.field, $event.value)"
            ></price-group-form>
          </template>
        </modal-model-form>
      </div>
    </template>
  </cinema-page>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import { getNoun } from '../../utils/string';

import CinemaPage from '../../layout/CinemaPage.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PriceGroupForm from './PriceGroupForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    Datatable,
    PriceGroupForm,
    ModalModelForm,
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        priceGroup: {
          show: false,
          id: '',
        },
      },
    };
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    disablePriceGroup(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 0 });
    },

    enablePriceGroup(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 1 });
    },

    openCreatePriceGroupForm() {
      this.modals.priceGroup.id = null;
      this.modals.priceGroup.show = true;
    },

    openEditPriceGroupForm(id) {
      this.modals.priceGroup.id = id;
      this.modals.priceGroup.show = true;
    },

    async removePriceGroup(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    openPrices(id) {
      this.$router.push({
        name: 'prices',
        query: {
          priceGroupId: id,
        },
      });
    },

    formatPricesCount(count) {
      return `${count} ${getNoun(count, 'ценовая политика', 'ценовых политики', 'ценовых политик')}`;
    },
  },
};
</script>

<style scoped>
</style>
