<template>
  <div class="p-1 pt-3">
    <info-card>
      <info-card-row
          v-if="item.error"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Ошибка получения данных:</template>
        <template #value>{{ item.error }}</template>
      </info-card-row>

      <info-card-row
          v-if="item.merchantAccountName"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Мерчант аккаунт:</template>
        <template #value>{{ item.merchantAccountName }}</template>
      </info-card-row>

      <info-card-row
          v-if="item.acquiringOrderId"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Номер заказа в эквайринге:</template>
        <template #value>{{ item.acquiringOrderId }}</template>
      </info-card-row>

      <info-card-row
        v-if="item.orderNumber"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Номер заказа:</template>
        <template #value>{{ item.orderNumber }}</template>
      </info-card-row>

      <info-card-row
        v-if="item.date"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Дата регистрации заказа:</template>
        <template #value>{{ formatDatetime(item.date) }}</template>
      </info-card-row>

      <info-card-row
          v-if="item.amount"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Сумма платежа:</template>
        <template #value>{{ formatPrice(item.amount) }}</template>
      </info-card-row>

      <info-card-row
          v-if="item.serviceFee"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Сервисный сбор:</template>
        <template #value>{{ formatPrice(item.serviceFee) }}</template>
      </info-card-row>

      <info-card-row
        v-if="item.statusMessage"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Состояние заказа:</template>
        <template #value>{{ item.statusMessage }}</template>
      </info-card-row>

      <info-card-row
        v-if="item.terminalId"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Идентификатор терминала:</template>
        <template #value>{{ item.terminalId }}</template>
      </info-card-row>

      <info-card-row
        v-if="item.errorMessage"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Причина отмены авторизации:</template>
        <template #value>{{ item.errorMessage }}</template>
      </info-card-row>
    </info-card>

    <info-card
      v-if="item.authorization"
      class="mt-4 mb-4 p-2"
      title="Авторизация"
    >
      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Дата операции:</template>
        <template #value>{{ formatDatetime(item.authorization.date) }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>RRN операции:</template>
        <template #value>{{ item.authorization.referenceNumber }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Код авторизации:</template>
        <template #value>{{ item.authorization.approvalCode }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Карта клиента:</template>
        <template #value>
          <span>{{ item.authorization.cardNumber }}</span>
          <span v-if="item.authorization.cardExpiration" class="ml-1">(до {{ formatCardExpiration(item.authorization.cardExpiration) }})</span>
        </template>
      </info-card-row>

      <info-card-row
          v-if="item.authorization.cardBankName"
          column-one="3"
          column-one-class="text-left font-weight-light"
      >
        <template #label>Наименование банка-эмитента:</template>
        <template #value>{{ item.authorization.cardBankName }}</template>
      </info-card-row>
    </info-card>

    <info-card
      v-for="refund in item.refunds"
      class="mt-4 mb-4 p-2"
      title="Возврат"
    >
      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Дата операции:</template>
        <template #value>{{ formatDatetime(refund.date) }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>RRN операции:</template>
        <template #value>{{ refund.referenceNumber }}</template>
      </info-card-row>

      <info-card-row
        v-if="refund.approvalCode"
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Код авторизации:</template>
        <template #value>{{ refund.approvalCode }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Сумма:</template>
        <template #value>{{ formatPrice(refund.amount) }}</template>
      </info-card-row>

      <info-card-row
        column-one="3"
        column-one-class="text-left font-weight-light"
      >
        <template #label>Код ответа платёжного шлюза:</template>
        <template #value>{{ refund.actionCode }} <span class="text-secondary" v-if="refund.actionCodeMessage">({{ refund.actionCodeMessage }})</span></template>
      </info-card-row>
    </info-card>
  </div>
</template>

<script>
import InfoCard from '../../components/card/InfoCard.vue';
import InfoCardRow from '../../components/card/InfoCardRow.vue';

export default {
  components: {
    InfoCard,
    InfoCardRow,
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU").format(price);
    },
    formatDatetime(datetime) {
      return this.$datetime.format(datetime, 'D MMMM YYYY, HH:mm:ss');
    },
    formatCardExpiration(exp) {
      return exp.match(/.{1,4}/g).join('/');
    }
  },
  props: {
    item: {
      default: null,
    },
  },
};
</script>

<style scoped>
.table >>> th {
  font-weight: 400;
  font-size: 14px;
}
.table >>> td {
  font-weight: 300;
  font-size: 14px;
}
</style>
