export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditPriceGroupForm',
        permission: 'prices.edit',
      },
      {
        type: 'button',
        text: 'Открыть политики',
        action: 'openPrices',
        permission: 'prices.edit',
      },
      {
        type: 'button',
        text: item.isEnabled ? 'Отключить' : 'Включить',
        action: item.isEnabled ? 'disablePriceGroup' : 'enablePriceGroup',
        permission: 'prices.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removePriceGroup',
        permission: 'prices.edit',
      },
    ],
  },
];
