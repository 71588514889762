<template>
  <modal-form-dialog
    ok-title="Копировать"
    title="Копирование порядка баннеров"
    size="md"
    :save="copyPositions"
    :close="() => true"
    :busy="loading"
    @hidden="$emit('hide')"
  >
    <b-form-group
      label="Период:"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="dateStart"
        :disabled="loading"
        @change="changeWeekStart($event)"
      ></date-popover>

      <date-popover
        size="sm"
        :value="dateEnd"
        :disabled="loading"
        @change="changeWeekEnd($event)"
      ></date-popover>
    </b-form-group>

    <b-form-group label="Кинотеатры:">
      <dictionary-provider name="cinemas">
        <template #default="{ items: cinemas, loading: cinemasLoading }">
          <div>
            <b-checkbox
              v-for="cinema in cinemas" :key="cinema.id"
              :checked="cinemaIds.includes(cinema.id)"
              :disabled="cinemasLoading || loading"
              @change="cinemaIds = toggleId(cinemaIds, cinema.id)"
            >
              {{ cinema.name }} ({{cinema.city.name}}), {{ cinema.hallsCount }} {{ getNoun(cinema.hallsCount, 'зал', 'зала', 'залов') }}
            </b-checkbox>
          </div>
        </template>
      </dictionary-provider>
    </b-form-group>
  </modal-form-dialog>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import DatePopover from '../../components/fields/DatePopover.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';

import { getNoun } from '../../utils/string';

export default {
  components: {
    ModalFormDialog,
    DatePopover,
    DictionaryProvider,
  },
  props: {
    cinemaId: {
      default: null,
    },
    initDateStart: {
      default: null,
    },
    initDateEnd: {
      default: null,
    },
    positions: {
      default: () => [],
    },
  },
  data() {
    return {
      cinemaIds: [],
      loading: false,
      dateStart: null,
      dateEnd: null,
    };
  },
  watch: {
    cinemaId: {
      handler() {
        this.cinemaIds = [];
      },
      immediate: true,
    },
    initDateStart: {
      handler() {
        this.dateStart = this.$datetime.convertDateToDbFormat(this.initDateStart);
      },
      immediate: true,
    },
    initDateEnd: {
      handler() {
        this.dateEnd = this.$datetime.convertDateToDbFormat(this.initDateEnd);
      },
      immediate: true,
    },
  },
  computed: {
    weeks() {
      return this.$datetime.getRepertoryWeeks(this.dateStart, this.dateEnd).map((item) => ({
        dateStart: this.$datetime.convertDateToDbFormat(item.dateStart),
        dateEnd: this.$datetime.convertDateToDbFormat(item.dateEnd),
      }));
    },
  },
  methods: {
    changeWeekStart(date) {
      this.dateStart = this.$datetime.convertDateToDbFormat(this.$datetime.findWeekStart(date));

      if (this.$datetime.isDateSameOrBefore(this.dateEnd, this.dateStart)) {
        this.dateEnd = this.$datetime.convertDateToDbFormat(this.$datetime.addDays(this.dateStart, 6));
      }
    },

    changeWeekEnd(date) {
      this.dateEnd = this.$datetime.convertDateToDbFormat(this.$datetime.findWeekEnd(date));

      if (this.$datetime.isDateSameOrBefore(this.dateEnd, this.dateStart)) {
        this.dateStart = this.$datetime.convertDateToDbFormat(this.$datetime.subtractDays(this.dateEnd, 6));
      }
    },

    toggleId(ids, id) {
      const idsCopy = [...ids];

      const index = idsCopy.indexOf(id);

      if (index > -1) {
        idsCopy.splice(index, 1);
      } else {
        idsCopy.push(id);
      }

      return idsCopy;
    },

    async copyPositions() {
      const promises = [];

      this.loading = true;

      for (const week of this.weeks) {
        for (const cinemaId of this.cinemaIds) {
          const values = {
            cinema_id: cinemaId,
            date_start: week.dateStart,
            date_end: week.dateEnd,
            positions: this.positions,
          };

          const promise = this.$store.dispatch('data/banners/changePositions', { values });

          promises.push(promise);
        }
      }

      await Promise.all(promises);

      this.loading = false;
    },

    getNoun(number,
      one,
      two,
      five) {
      return getNoun(number,
        one,
        two,
        five);
    },
  },
};
</script>
