export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'priceGroupId',
    type: 'text',
    label: 'Даты',
    filterable: true,
    sortable: false,
  },
  {
    key: 'day',
    type: 'select',
    label: 'Дни недели',
    sortable: false,
    filterable: true,
  },
  {
    key: 'time',
    type: 'time',
    label: 'Время',
    sortable: false,
    filterable: true,
  },
  {
    key: 'formatId',
    type: 'select',
    label: 'Форматы фильмов',
    sortable: false,
    filterable: true,
  },
  {
    key: 'hallId',
    type: 'select',
    label: 'Залы',
    sortable: false,
    filterable: true,
  },
  {
    key: 'colorSchemeId',
    type: 'select',
    label: 'Ценовые пояса',
    sortable: false,
    filterable: true,
  },
  {
    key: 'price',
    type: 'interval',
    label: 'Цена',
    sortable: false,
    filterable: true,
  },
];
