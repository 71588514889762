import advertisementTypes from './advertisementTypes';
import advPromotions from './advPromotions';
import ageLimits from './ageLimits';
import appGroups from './appGroups';
import apps from './apps';
import banners from './banners';
import cashierDeviceProtocols from './cashierDeviceProtocols';
import cashierPlaces from './cashierPlaces';
import certificateGroups from './certificateGroups';
import certificateUseModes from './certificateUseModes';
import cinemas from './cinemas';
import cities from './cities';
import colorSchemes from './colorSchemes';
import contactTypes from './contactTypes';
import countries from './countries';
import countries2 from './countries2';
import dcpKeys from './dcpKeys';
import discounts from './discounts';
import distributorAgreementMovies from './distributorAgreementMovies';
import distributorAgreements from './distributorAgreements';
import distributors from './distributors';
import distributors2 from './distributors2';
import documents from './documents';
import ekbRegions from './ekbRegions';
import employees from './employees';
import employeeSessions from './employeeSessions';
import employeeTypes from './employeeTypes';
import favoriteReleases from './favoriteReleases';
import formats from './formats';
import formats2 from './formats2';
import genres from './genres';
import genres2 from './genres2';
import hallCategories from './hallCategories';
import halls from './halls';
import holidays from './holidays';
import kiosks from './kiosks';
import labs from './labs';
import legalEntities from './legalEntities';
import mailAccounts from './mailAccounts';
import marketIndicators from './marketIndicators';
import mediaServerTypes from './mediaServerTypes';
import merchantAccounts from './merchantAccounts';
import merchantProviders from './merchantProviders';
import movies from './movies';
import movies2 from './movies2';
import notificationPlaces from './notificationPlaces';
import organizations from './organizations';
import paymentMethods from './paymentMethods';
import paymentSubjects from './paymentSubjects';
import paymentTypes from './paymentTypes';
import permissions from './permissions';
import privileges from './privileges';
import priceGroups from './priceGroups';
import productGroups from './productGroups';
import productReportGroups from './productReportGroups';
import projectorTypes from './projectorTypes';
import promocodeGroups from './promocodeGroups';
import promocodeUseModes from './promocodeUseModes';
import promotions from './promotions';
import pushkinsCardEvents from './pushkinsCardEvents';
import qaCategories from './qaCategories';
import qaItems from './qaItems';
import regions from './regions';
import relayBoardTypes from './relayBoardTypes';
import releases from './releases';
import releases2 from './releases2';
import rentrakCinemas from './rentrakCinemas';
import repertoryItemStatuses from './repertoryItemStatuses';
import repertoryReleases from './repertoryReleases';
import roles from './roles';
import salesReleases from './salesReleases';
import seatTypes from './seatTypes';
import selectionGroups from './selectionGroups';
import shows from './shows';
import showsReleases from './showsReleases';
import socialNetworks from './socialNetworks';
import soundProcessorTypes from './soundProcessorTypes';
import tags from './tags';
import targets from './targets';
import taxSystems from './taxSystems';
import users from './users';
import utcOffsets from './utcOffsets';
import vats from './vats';
import webPages from './webPages';

export default {
  namespaced: true,

  modules: {
    advertisementTypes,
    advPromotions,
    ageLimits,
    appGroups,
    apps,
    banners,
    cashierDeviceProtocols,
    cashierPlaces,
    certificateGroups,
    certificateUseModes,
    cinemas,
    cities,
    colorSchemes,
    contactTypes,
    countries,
    countries2,
    dcpKeys,
    discounts,
    distributorAgreementMovies,
    distributorAgreements,
    distributors,
    distributors2,
    documents,
    ekbRegions,
    employees,
    employeeSessions,
    employeeTypes,
    favoriteReleases,
    formats,
    formats2,
    genres,
    genres2,
    hallCategories,
    halls,
    holidays,
    kiosks,
    labs,
    legalEntities,
    mailAccounts,
    marketIndicators,
    mediaServerTypes,
    merchantAccounts,
    merchantProviders,
    movies,
    movies2,
    notificationPlaces,
    organizations,
    paymentMethods,
    paymentSubjects,
    paymentTypes,
    permissions,
    priceGroups,
    privileges,
    productGroups,
    productReportGroups,
    projectorTypes,
    promocodeGroups,
    promocodeUseModes,
    promotions,
    pushkinsCardEvents,
    qaCategories,
    qaItems,
    regions,
    relayBoardTypes,
    releases,
    releases2,
    rentrakCinemas,
    repertoryItemStatuses,
    repertoryReleases,
    roles,
    salesReleases,
    seatTypes,
    selectionGroups,
    shows,
    showsReleases,
    socialNetworks,
    soundProcessorTypes,
    tags,
    targets,
    taxSystems,
    users,
    utcOffsets,
    vats,
    webPages,
  },
};
