<template>
  <cinema-page
    route-name="prices"
    route-param-name="cinemaId"
    @loaded="setCinemaId($event)"
  >
    <template #scroll="{ cinemaId, halls }">
      <collection-provider
        collection="prices"
        ref="collection"
        :filter="{ cinemaId, priceGroupId: $route.query.priceGroupId || null }"
        :disable-fetching="!cinemaId"
      >
        <template #default="{ data, actions }">
          <dictionaries-provider :names="['colorSchemes', 'formats2']">
            <template #default="{ colorSchemes, formats2 }">

              <datatable
                class="prices"
                v-bind="{ ...data, ...actions }"
                :fields="datatable.fields"
                :permissions="$user.permissions"
                :item-actions="datatable.itemActions"
                :panel-actions="panelActions"
                :checked="datatable.checked"
                @change-checked="datatable.checked = $event"
                @item-action="performItemAction($event)"
                @panel-action="performPanelAction($event)"
              >
                <template #filter(day)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.day"
                    :options="days"
                    :disabled="data.loading"
                    @change="actions.changeFilter('day', $event)"
                  ></b-form-select>
                </template>

                <template #filter(priceGroupId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.priceGroupId"
                    :options="priceGroupsOptions"
                    :disabled="data.loading"
                    @change="actions.changeFilter('priceGroupId', $event)"
                  >
                    <template #first>
                      <b-form-select-option :value="null">Без даты</b-form-select-option>
                    </template>
                  </b-form-select>
                </template>

                <template #filter(formatId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.formatId"
                    :options="$array.convertToOptions(formats2)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('formatId', $event)"
                  ></b-form-select>
                </template>

                <template #filter(hallId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.hallId"
                    :options="$array.convertToOptions(halls)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('hallId', $event)"
                  ></b-form-select>
                </template>

                <template #filter(colorSchemeId)>
                  <b-form-select
                    size="sm"
                    :value="data.filter.colorSchemeId"
                    :options="$array.convertToOptions(colorSchemes)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('colorSchemeId', $event)"
                  ></b-form-select>
                </template>

                <template #cell(priceGroupId)="data">
                  <div
                    class="mw-200vw"
                  >{{ data.item.priceGroupId ? formatPriceGroup(data.item.priceGroupId) : 'Без даты' }}</div>
                </template>

                <template #cell(day)="data">
                  {{$array.convertToText(data.item.days, 'Все', 'short')}}
                </template>

                <template #cell(time)="data">
                  <span v-if="data.item.timeStart" class="mr-1">с  {{data.item.timeStart}}</span>
                  <span v-if="data.item.timeEnd">по {{data.item.timeEnd}}</span>
                </template>

                <template #cell(hallId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.halls, 'Все')"
                  >{{$array.convertToText(data.item.halls, 'Все')}}</div>
                </template>

                <template #cell(formatId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.formats, 'Все')"
                  >{{$array.convertToText(data.item.formats, 'Все')}}</div>
                </template>

                <template #cell(colorSchemeId)="data">
                  <div
                    class="mw-200vw"
                    :title="$array.convertToText(data.item.colorSchemes, 'Все')"
                  >{{$array.convertToText(data.item.colorSchemes, 'Все')}}</div>
                </template>
              </datatable>
            </template>
          </dictionaries-provider>
        </template>
      </collection-provider>

      <modal-model-form
        v-if="modals.price.show"
        create-title="Создание ценовой политики"
        edit-title="Редактирование ценовой политики"
        collection="prices"
        :id="modals.price.id"
        :init="{ cinemaId, priceGroupId }"
        @close="modals.price.show = false"
        @saved="$refs.collection.fetchItems()"
      >
        <template #default="{ data, actions }">
          <price-form
            :loading="data.loading"
            :errors="data.errors"
            :states="data.states"
            :item="data.item"
            :halls="halls"
            @change="actions.setValue($event.field, $event.value)"
          ></price-form>
        </template>
      </modal-model-form>
    </template>
  </cinema-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { confirmDialog } from '../../utils/dialogs';
import CinemaPage from '../../layout/CinemaPage.vue';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import PriceForm from './PriceForm.vue';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

export default {
  components: {
    CinemaPage,
    CollectionProvider,
    DictionariesProvider,
    Datatable,
    PriceForm,
    ModalModelForm,
  },
  data() {
    return {
      cinemaId: null,
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        price: {
          show: false,
          id: '',
        },
      },
      days: [
        { value: null, text: '' },
        { value: 1, text: 'ПН' },
        { value: 2, text: 'ВТ' },
        { value: 3, text: 'СР' },
        { value: 4, text: 'ЧТ' },
        { value: 5, text: 'ПТ' },
        { value: 6, text: 'СБ' },
        { value: 7, text: 'ВС' },
      ],
    };
  },
  computed: {
    ...mapState('data/prices', {
      priceGroupId: (state) => state.filter.priceGroupId,
    }),

    ...mapState('dictionaries/priceGroups', {
      priceGroups: (state) => state.items,
    }),

    priceGroupsOptions() {
      return this.priceGroups.map((priceGroup) => ({
        value: priceGroup.id,
        text: `${this.formatPriceGroupDates(priceGroup)}${!priceGroup.isEnabled ? ' - отключена' : ''}`,
      }));
    },
  },
  watch: {
    cinemaId: {
      handler() {
        if (!this.cinemaId) {
          return;
        }

        this.fetchPriceGroups({ filter: { cinemaId: this.cinemaId } });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('dictionaries/priceGroups', {
      fetchPriceGroups: 'fetchItems',
    }),

    setCinemaId(cinema) {
      this.cinemaId = cinema.id;
    },

    formatPriceGroupDates(priceGroup) {
      return priceGroup ? `с ${this.$datetime.formatDate(priceGroup.dateStart)} по ${this.$datetime.formatDate(priceGroup.dateEnd)}` : '';
    },

    formatPriceGroup(priceGroupId) {
      const priceGroup = this.priceGroups.find((item) => item.id === priceGroupId);
      return `${this.formatPriceGroupDates(priceGroup)}${!priceGroup.isEnabled ? ' - отключена' : ''}`;
    },

    panelActions() {
      const actions = panelActions({ checked: this.datatable.checked });

      if (this.priceGroupId) {
        return [...actions, {
          type: 'button',
          text: 'Заполнить политиками по умолчанию',
          variant: 'info',
          class: 'ml-1',
          action: 'copyPrices',
          permission: 'prices.edit',
        }];
      }

      return actions;
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    openCreatePriceForm() {
      this.modals.price.id = null;
      this.modals.price.show = true;
    },

    openEditPriceForm(id) {
      this.modals.price.id = id;
      this.modals.price.show = true;
    },

    async removeCheckedPrices() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    async removePrice(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    async copyPrices() {
      await this.$store.dispatch('data/prices/copyPrices', this.priceGroupId);
    },
  },
};
</script>

<style scoped>
.prices >>> .mw-200vw {
  max-width: 22vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prices >>> .column-price {
  width: 10vw;
}
</style>
