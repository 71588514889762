var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cinema-page',{attrs:{"route-name":"priceGroups","route-param-name":"cinemaId"},scopedSlots:_vm._u([{key:"scroll",fn:function(ref){
var cinemaId = ref.cinemaId;
return [_c('div',[_c('collection-provider',{ref:"collection",attrs:{"collection":"priceGroups","filter":{ cinemaId: cinemaId },"disable-fetching":!cinemaId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"permissions":_vm.$user.permissions,"item-actions":_vm.datatable.itemActions,"panel-actions":_vm.datatable.panelActions},on:{"item-action":function($event){return _vm.performItemAction($event)},"panel-action":function($event){return _vm.performPanelAction($event)}},scopedSlots:_vm._u([{key:"cell(dateStart)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$datetime.formatDate(data.item.dateStart))+" ")]}},{key:"cell(dateEnd)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$datetime.formatDate(data.item.dateEnd))+" ")]}},{key:"cell(enabled)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.isEnabled ? 'Включена' : 'Отключена')+" ")]}},{key:"cell(prices)",fn:function(data){return [_c('a',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.openPrices(data.item.id)}}},[_vm._v(_vm._s(data.item.prices.length ? _vm.formatPricesCount(data.item.prices.length) : 'Нет политик'))])]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]}}],null,true)}),(_vm.modals.priceGroup.show)?_c('modal-model-form',{attrs:{"create-title":"Создание группы ценовых политик","edit-title":"Редактирование группы ценовых политик","collection":"priceGroups","id":_vm.modals.priceGroup.id,"init":{ cinemaId: cinemaId }},on:{"close":function($event){_vm.modals.priceGroup.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('price-group-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }