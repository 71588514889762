import Advertisements from '../pages/advertisements/Index.vue';
import AdvPromotions from '../pages/adv-promotions/Index.vue';
import Banners from '../pages/banners/Index.vue';
import CertificateGroups from '../pages/certificate-groups/Index.vue';
import Certificates from '../pages/certificates/Index.vue';
import ColorSchemes from '../pages/color-schemes/Index.vue';
import Discounts from '../pages/discounts/Index.vue';
import Full from '../layout/Full.vue';
import Holidays from '../pages/holidays/Index.vue';
import Mailing from '../pages/mailing/Index.vue';
import Notifications from '../pages/notifications/Index.vue';
import Prices from '../pages/prices/Index.vue';
import Privileges from '../pages/privileges/Index.vue';
import Promotions from '../pages/promotions/Index.vue';
import QaCategories from '../pages/qa-categories/Index.vue';
import QaItems from '../pages/qa-items/Index.vue';
import Tags from '../pages/tags/Index.vue';
import PromocodeGroups from '../pages/promocode-groups/Index.vue';
import Promocodes from '../pages/promocodes/Index.vue';
import WebPages from '../pages/web-pages/Index.vue';
import WebMenus from '../pages/web-menus/Index.vue';
import PriceGroups from '../pages/price-groups/Index.vue';

export default {
  path: '/marketing',
  redirect: '/marketing/promotions',
  component: Full,
  children: [
    {
      name: 'discounts',
      path: 'discounts',
      component: Discounts,
      meta: {
        auth: true,
        title: 'Действующие скидки',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Действующие скидки' },
        ],
        scrollable: true,
        permissions: ['discounts.view'],
      },
    },
    {
      name: 'promotions',
      path: 'promotions',
      component: Promotions,
      meta: {
        auth: true,
        title: 'Действующие акции',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Действующие акции' },
        ],
        scrollable: true,
        permissions: ['promotions.view'],
      },
    },
    {
      name: 'certificateGroups',
      path: 'certificate-groups',
      component: CertificateGroups,
      meta: {
        auth: true,
        title: 'Группы сертификатов',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Сертификаты' },
        ],
        scrollable: true,
        permissions: ['certificates.view'],
      },
    },
    {
      name: 'certificates',
      path: 'certificates',
      component: Certificates,
      meta: {
        auth: true,
        title: 'Сертификаты',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Сертификаты' },
        ],
        scrollable: true,
        permissions: ['certificates.view'],
      },
    },
    {
      name: 'promocodeGroups',
      path: 'promocode-groups',
      component: PromocodeGroups,
      meta: {
        auth: true,
        title: 'Группы промокодов',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Промокоды' },
        ],
        scrollable: true,
        permissions: ['promocodes.view'],
      },
    },
    {
      name: 'promocodes',
      path: 'promocodes',
      component: Promocodes,
      meta: {
        auth: true,
        title: 'Промокоды',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Промокоды' },
        ],
        scrollable: true,
        permissions: ['promocodes.view'],
      },
    },
    {
      name: 'privileges',
      path: 'privileges',
      component: Privileges,
      meta: {
        auth: true,
        title: 'Льготные категории',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Льготные категории' },
        ],
        scrollable: true,
        permissions: ['privileges.view'],
      },
    },
    {
      name: 'holidays',
      path: 'holidays',
      component: Holidays,
      meta: {
        auth: true,
        title: 'Праздничные даты',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Праздничные даты' },
        ],
        scrollable: true,
        permissions: ['holidays.view'],
      },
    },
    {
      name: 'mailing',
      path: 'mailing',
      component: Mailing,
      meta: {
        auth: true,
        title: 'Рассылки',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Рассылки' },
        ],
        permissions: ['holidays.view'],
      },
    },
    {
      name: 'colorSchemes',
      path: 'color-schemes',
      component: ColorSchemes,
      meta: {
        auth: true,
        title: 'Ценовые пояса',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Ценовые пояса' },
        ],
        scrollable: true,
        permissions: ['color_schemes.view'],
      },
    },
    {
      name: 'prices',
      path: 'prices/:cinemaId?',
      component: Prices,
      meta: {
        auth: true,
        title: 'Ценовые политики',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Ценовые политики' },
        ],
        permissions: ['prices.view'],
      },
    },
    {
      name: 'advertisements',
      path: 'advertisements',
      component: Advertisements,
      meta: {
        auth: true,
        title: 'Планирование рекламы',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Планирование рекламы' },
        ],
        permissions: ['advertisements.view'],
      },
    },
    {
      name: 'advPromotions',
      path: 'adv-promotions',
      component: AdvPromotions,
      meta: {
        auth: true,
        title: 'Рекламные акции',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Рекламные акции' },
        ],
        permissions: ['adv_promotions.view'],
      },
    },
    {
      name: 'banners',
      path: 'banners',
      component: Banners,
      meta: {
        auth: true,
        title: 'Баннеры для сайта',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Баннеры для сайта' },
        ],
        permissions: ['banners.view'],
      },
    },
    {
      name: 'qaCategories',
      path: 'qa-categories',
      component: QaCategories,
      meta: {
        auth: true,
        title: 'Категории вопросов',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Вопрос-ответ' },
          { name: 'Категории вопросов' },
        ],
        scrollable: true,
        permissions: ['qa.categories.view'],
      },
    },
    {
      name: 'qaItems',
      path: 'qa-items',
      component: QaItems,
      meta: {
        auth: true,
        title: 'Вопрос-ответ',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Вопрос-ответ' },
          { name: 'Вопросы' },
        ],
        scrollable: true,
        permissions: ['qa.items.view'],
      },
    },
    {
      name: 'notifications',
      path: 'notifications',
      component: Notifications,
      meta: {
        auth: true,
        title: 'Уведомления',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Уведомления' },
        ],
        scrollable: true,
        permissions: ['notifications.view'],
      },
    },
    {
      name: 'tags',
      path: 'tags',
      component: Tags,
      meta: {
        auth: true,
        title: 'Метки',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Метки' },
        ],
        scrollable: true,
        permissions: ['tags.view'],
      },
    },
    {
      name: 'webPages',
      path: 'web-pages',
      component: WebPages,
      meta: {
        auth: true,
        title: 'Страницы сайта',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Страницы сайта' },
        ],
        permissions: ['web_pages.view'],
      },
    },
    {
      name: 'webMenus',
      path: 'web-menus',
      component: WebMenus,
      meta: {
        auth: true,
        title: 'Меню сайта',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Меню сайта' },
        ],
        permissions: ['web_menus.view'],
      },
    },
    {
      name: 'priceGroups',
      path: 'price-groups/:cinemaId?',
      component: PriceGroups,
      meta: {
        auth: true,
        title: 'Группы ценовых политик',
        breadcrumbs: [
          { name: 'Маркетинг' },
          { name: 'Группы ценовых политик' },
        ],
        permissions: ['prices.view'],
      },
    },
  ],
};
