import _factory from './_factory';
import pricesService from '../../services/Prices';

export default {
  ..._factory('prices', {
    state: {
      filter: {
        priceGroupId: null,
      },
    },
    actions: {
      async copyPrices({
        commit, dispatch,
      }, priceGroupId) {
        commit('setLoading', true);

        const [err, result] = await pricesService.copyPrices({ priceGroupId });

        await dispatch('fetchItems');

        commit('setLoading', false);

        return [err, result];
      },
    },
  }, pricesService),
};
