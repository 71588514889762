export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'dateStart',
    type: 'text',
    label: 'Дата начала действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'dateEnd',
    type: 'text',
    label: 'Дата окончания действия',
    sortable: false,
    filterable: false,
  },
  {
    key: 'prices',
    type: 'text',
    label: 'Ценовые политики',
    sortable: false,
    filterable: false,
  },
  {
    key: 'enabled',
    type: 'select',
    label: 'Статус',
    sortable: false,
    filterable: false,
  },
];
